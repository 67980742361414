<template>
  <div class="upcoming-events-show">

    <b-container class="pt-5 pb-5">
      <b-row>
        <b-col col md="8" class=" mb-5">
          <b-row>
            <b-col col md="12" class="">
              <h1>Eventos </h1>
              <hr class="line sm" />
            </b-col>

            <b-col cols="12" class="mb-4">
              <h2>
                <span class="light blue-grey-text">2021-10-31 </span>
                <span class="strong blue-grey-text text-lighten-4"> / </span> 
                <span class="strong blue-grey-text text-darken-2">Evento 1</span>
              </h2>
              <p class="lead">Donec vel nisi in ante bibendum pulvinar quis sed massa.
                Ut sit amet velit nibh. Quisque ac elit est. Nulla nec vehicula dolor.
                Mauris nec urna mauris. Proin eu est massa.</p>

              <ul class="lead">
                <li><a href="#">Link Facebook</a></li>
                <li><a href="#">Link Youtube</a></li>
                <li><a href="#">Link Twitter</a></li>
                <li><a href="#">Link Periscope</a></li>
              </ul>

              <b-tabs content-class="mt-3">
                <b-tab title="¡En vivo!" active>
                  <b-embed
                    type="iframe"
                    aspect="16by9"
                    :src="`https://www.youtube-nocookie.com/embed/zPY7N7tORkE?rel=0`"
                    allowfullscreen
                    class="video"
                  ></b-embed>
                </b-tab>
                <b-tab title="Ubicación"><p>Ubicación</p></b-tab>
              </b-tabs>

            </b-col>


          </b-row>
        </b-col>

        <b-col cols="4">
          <Sidebar msg="Paginas" />
        </b-col>

      </b-row>
    </b-container>

  </div>
</template>

<script>
import Sidebar from '@/components/layout/Sidebar.vue';

export default{
  name: 'UpcomingEventsShow',
  components: {
    Sidebar,
  },
}
</script>
